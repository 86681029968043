const video = document.querySelector('.video');
const sound_btn = document.querySelectorAll('.header-sound');
const mv = document.querySelector('.mv');
const mv_text = document.querySelector('.mv-text');
const mv_scroll = document.querySelector('.mv-scroll');


const mediaQuery = window.matchMedia('(max-width: 900px)');
// ページが読み込まれた時に実行
handle(mediaQuery);
// ウィンドウサイズを変更しても実行（ブレイクポイントの監視）
mediaQuery.addListener(handle);
function handle(mm) {
    if (mm.matches) {
        // ウィンドウサイズ900px以下のときの処理
        video.src = '/assets/audio/mv_sp.mp4';
        video.poster = '/assets/img/home/video-img.sp.jpg';
    } else {
        // それ以外の処理
        video.src = '/assets/audio/mv_pc.mp4';
        video.poster = '/assets/img/home/video-img.pc.jpg';
    }
}

window.addEventListener('load', (e) => {
    setTimeout(function() {
        mv.classList.remove('intro');
        mv.classList.add('load');
        video.autoplay = true;
        video.play()
    }, 2000);
})

video.addEventListener('playing', function() {
    setTimeout(function() {
        mv_text.classList.add('is-active');
    }, 1000);
    setTimeout(function() {
        mv_scroll.classList.add('is-active');
    }, 2000);
});

sound_btn.forEach((sound) => {
    sound.addEventListener('click', (event) => {
        if(video.muted) {
            video.muted = false;
            sound.classList.add('sound-on');
        } else if(!video.muted) {
            video.muted = true;
            sound.classList.remove('sound-on');
        }
    });
});

